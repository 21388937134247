<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center" style="width: 200px">
              {{ $t("labels.oa") }}
            </th>
            <th role="columnheader" class="text-center" style="width: 200px">
              {{ $t("labels.classify") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.name") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.template_id") }}
            </th>
            <!-- <th role="columnheader" class="text-center" >
              {{ $t("labels.template") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.price") }}/tin
            </th> -->
            <th role="columnheader" class="text-center" style="width: 150px">
              {{ $t("labels.status") }}
            </th>
            <th role="columnheader" class="text-center" style="width: 150px">
              <v-btn
                small
                color="primary"
                @click="addItem"
                :disabled="isDisabledBtnAdd"
              >
                <v-icon>mdi-plus</v-icon>
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, index) in items"
            :key="`c_${index}_${item.id}`"
          >
            <td>
              <span v-if="!item.editing">{{ item.customer_zalo_name }}</span>
              <v-autocomplete
                v-else
                class="c-input-small"
                v-model="item.id_customer_zalo"
                :items="accountOptions"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <span v-if="!item.editing">
                {{ $t(`labels.zalo_template_action_type_${item.action_type}`) }}
              </span>
              <v-autocomplete
                v-else
                class="c-input-small"
                v-model="item.action_type"
                :items="actionTypeOptions"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.name }}</span>
              <v-text-field
                v-else
                v-model="item.name"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.zalo_template_id }}</span>
              <v-text-field
                v-else
                v-model="item.zalo_template_id"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <!-- <td>
              <span
                class="cursor-pointer primary--text text-decoration-underline"
                @click="showImages(item.template_sample_image)"
              >
                {{ item.template_sample_name }}
              </span>
            </td>
            <td>{{ item.price ? formatNumber(item.price) : "" }}</td> -->
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <span v-if="!item.editing">
                  {{ item.status ? "Hoạt động" : "Dừng hoạt động" }}
                </span>
                <v-checkbox
                  v-else
                  v-model="item.status"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <template v-if="!item.editing">
                <v-btn
                  class="mr-1"
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
              </template>

              <template v-else>
                <v-btn
                  class="mr-1"
                  x-small
                  color="success"
                  @click="saveItem(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS, ZNS_ACTION_TYPES } from "@/libs/const";

export default {
  name: "ZaloOA",
  components: {},
  data: () => ({
    isLoading: false,
    sampleDialog: false,
    updatingItem: {},
    accounts: [],
    items: [],
    yesNoOptions: [...YES_NO_OPTIONS],
    actionTypeOptions: [...ZNS_ACTION_TYPES],
  }),
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    accountOptions() {
      return [...this.accounts].map((acc) => ({
        text: acc.name,
        value: acc.id,
      }));
    },
  },
  created() {},
  mounted() {
    this.getList();
    this.getOA();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    addItem() {
      const item = {
        editing: true,
        id: null,
        status: 1,
        name: null,
        price: null,
        zalo_template_id: null,
        id_zalo_zns_sample_template: 3, // otp sample
        template_sample_name: null,
        template_sample_image: null,
        template_sample_price: null,
        status_txt: null,
      };
      this.items.unshift(item);
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async saveItem(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/common/v1/save-zalo-zns-template", item);
        this.isLoading = false;
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async getOA() {
      try {
        const { data } = await httpClient.post("/common/v1/get-all-zalo-oa", {
          status: 1,
        });
        this.accounts = [...data];
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async getList() {
      try {
        const { data } = await httpClient.post(
          "/common/v1/get-zalo-zns-templates"
        );
        this.items = [...data];
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
